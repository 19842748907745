@import '../../utils.scss';

.menuAreaWrapper{
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10;
    display: flex;
    align-items: center;


    .changeLangWrapper { 
        display: flex;
        position: absolute;
        top: -75px;
        right: -20px;
        z-index: 30;
    }

    .changeLangWrapperMenu { 
        display: flex;
        position: absolute;
        top: 0px;
        right: 0px;
    }



    .changeLangMemnu { 
        color: #cdcdcd;
        height: 30px;
        border: none;
        cursor: pointer;
        background-color: Transparent;
        background-repeat:no-repeat;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:25px;

        &.en {
            padding: 40px 40px 40px 0px;
            margin-right: 70px;
        }

        &.ko {
            padding: 40px 30px 40px 40px;

        }

        &.select {
            color: $mainColor;
        }

    }

    .changeLang { 
        color: #cdcdcd;
        width: 50px;
        height: 30px;
        border: none;
        cursor: pointer;
        background-color: Transparent;
        background-repeat:no-repeat;
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        &.en { 
            margin-right: 10px; 
        }

        &.select {
            color: $mainColor;
        }

    }

        a
        {
        text-decoration: none;
        color: #4d4d4d;
        font-weight: 100;
        
        -webkit-transition: color 0.3s ease;
        
        -o-transition: color 0.3s ease;
        
        transition: color 0.3s ease;
        }

        a:hover
        {
        color: #ffffff;
        //font-weight: 200;
        }

        #menuToggle
        {
        display: block;
        position: fixed;
        top: 100px;
        right: 100px;
        
        z-index: 1;
        
        -webkit-user-select: none;
        -moz-user-select: none;
         -ms-user-select: none;
             user-select: none;
        }

        #menuToggle input
        {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        
        cursor: pointer;
        
        opacity: 0; /* hide this */
        z-index: 2; /* and place it over the hamburger */
        
        -webkit-touch-callout: none;
        }

        /*
        * Just a quick hamburger
        */
        #menuToggle span
        {
        display: block;
        width: 35px;
        height: 1px;
        margin-bottom: 7.5px;
        position: relative;
        
        background: #cdcdcd;
        
        z-index: 1;
        
        -webkit-transform-origin: 7px -2px;
        
            -ms-transform-origin: 7px -2px;
        
                transform-origin: 7px -2px;
        
        -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease,
                    -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        
        transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease,
                    -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        
        -o-transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
        
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
        
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease,
                    -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        }

        #menuToggle span:first-child
        {
            -webkit-transform-origin: 0% 0%;
                -ms-transform-origin: 0% 0%;
                    transform-origin: 0% 0%;
        }

        #menuToggle span:nth-last-child(2)
        {
            -webkit-transform-origin: 0% 100%;
                -ms-transform-origin: 0% 100%;
                    transform-origin: 0% 100%;
        }

        /* 
        * Transform all the slices of hamburger
        * into a crossmark.
        */
        #menuToggle input:checked ~ span
        {
            opacity: 1;
            -webkit-transform: rotate(45deg) translate(-2px, -1px);
                -ms-transform: rotate(45deg) translate(-2px, -1px);
                    transform: rotate(45deg) translate(-2px, -1px);
            background: #FFF;
        }

        /*
        * But let's hide the middle one.
        */
        #menuToggle input:checked ~ span:nth-last-child(3)
        {
            opacity: 0;
            -webkit-transform: rotate(0deg) scale(0.2, 0.2);
                -ms-transform: rotate(0deg) scale(0.2, 0.2);
                    transform: rotate(0deg) scale(0.2, 0.2);
        }

        /*
        * Ohyeah and the last one should go the other direction
        */
        #menuToggle input:checked ~ span:nth-last-child(2)
        {
            -webkit-transform: rotate(-45deg) translate(0, -1px);
                -ms-transform: rotate(-45deg) translate(0, -1px);
                    transform: rotate(-45deg) translate(0, -1px);
        }

        /*
        * Make this absolute positioned
        * at the top left of the screen
        */
        #menu {
            position: fixed;
            right: 0;
            height: 100%;
            margin-left: -475px;
            box-sizing: border-box;
            top: 0;
            transition: all 0.2s !important;

            > * {
                transition: all 0.2s;
            }
            
            background: #000;
            list-style-type: none;
            -webkit-font-smoothing: antialiased;
            /* to stop flickering of text in safari */
            
            -webkit-transform-origin: 0% 0%;
            
                -ms-transform-origin: 0% 0%;
            
                    transform-origin: 0% 0%;
            -webkit-transform: translate(100%, 0);
                -ms-transform: translate(100%, 0);
                    transform: translate(100%, 0);
            transition: transform 1s cubic-bezier(0.77,0.2,0.05,1.0);
            -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.77,0.05,0.05,1.0);
            transition: -webkit-transform 0.6s cubic-bezier(0.77,0.05,0.05,1.0);
            -o-transition: transform 0.6s cubic-bezier(0.77,0.05,0.05,1.0);
            transition: transform 0.6s cubic-bezier(0.77,0.05,0.05,1.0);
            transition: transform 0.6s cubic-bezier(0.77,0.05,0.05,1.0), -webkit-transform 0.6s cubic-bezier(0.77,0.05,0.05,1.0);

            .list {
                -webkit-transition: all 0.2s;
                -o-transition: all 0.2s;
                transition: all 0.2s;

                &.select {
                    color: white;
                }
            }

        }

        #menu li {
            padding: 8px 0;
            transition: all 0.2s;
        }

        /*
        * And let's slide it in from the left
        */
        #menuToggle input:checked ~ ul {
            -webkit-transform: none;
                -ms-transform: none;
                    transform: none;
        }



        .infoWrapper{
            position: absolute;
            width: 80%;
            border-top: 1px solid #4d4d4d;
            font-weight: 100;
            font-size: 12px;
            color: #FFF;
            background: #000;

            #adress{
                padding-top: 40px;
                padding-bottom: 20px;
                width: 80%;
            }

            .link{
                color: $mainColor;
                text-decoration: underline;
            }
        }
}

// 



@media (max-width: 1024px) {
    .menuAreaWrapper{ 

        left: 0;

        #menu {
            width: 460px;
    
            li {
                font-size: 36px;
            }
        }
        #menuToggle { 
            top: 40px;
            right: 100px;
            }
        .infoWrapper{
            #adress { 
                font-size: 15px;
                line-height: 20px;
            }
        } 
        }

    }
    

    @media (min-width: 781px) {
        #menu {
            width: 100%;
    
            li {
                font-size: 36px;
            }
        }
        
    
        .infoWrapper {
            bottom: 60px;
        }
    }

    

@media (max-width: 781px) {
    .menuAreaWrapper{ 
        #menu {
            width: 80%;

            li {
                font-size: 20px;
            }
        }
        #menuToggle { 
            top: 100px;
            right: 100px;

        }
        .infoWrapper{
            #adress { 
                font-size: 15px;
            }
        } 
    }
    

    .infoWrapper {
        bottom: 60px;
    }
}


@media (max-width: 780px) {
    #menu {
        width: 60%;

        li {
            font-size:  15px;
        }

        .infoWrapper {
            bottom: 80px;
        }
    }
}

@media (min-width: 781px) {
    #menu {
        width: 460px;
        padding: 50px;
        padding-top: 150px;

        li {
            font-size: 36px;
        }
    }
    
    

    .infoWrapper {
        bottom: 60px;
    }
}

@media (max-width: 780px) {
    #menu {
        padding: 100px;
        padding-top: 60px;
        width: 60%;

        li {
            font-size:  25px;
        }

        .infoWrapper {
            bottom: 80px;
        }
    }
}





@media (max-width: 681px) {
    .menuAreaWrapper {
        #menuToggle {
            top: 40px;
            right: 20px;
        }
    }
}


@media (max-height: 640px) {
    #menu {
        padding: 50px;
        padding-top: 60px;
        
        li {
            font-size: 10px;
        }
    }
  
}

@media (max-height: 550px) {
    #menu {
        padding-top: 60px;
        
        li {
            font-size: 10px;
        }
    }
    
}

@media (max-width: 768px) {
    .menuAreaWrapper {
        .changeLangWrapper { 
            top: 10px;
        }
    }
    
}

@media (max-width: 414px) {
    .menuAreaWrapper {
        .changeLangWrapper { 
            top: 20px;
        }
    }
    
}
@media (max-width: 411px) {
    .menuAreaWrapper {
        .changeLangWrapper { 
            top: 10px;
        }
    }
    
}

@media (max-width: 360px) {
    .menuAreaWrapper {
        .changeLangWrapper { 
            top: 10px;
        }
    }
    
}


@media (max-width: 320px) {
    .menuAreaWrapper {
        .changeLangWrapper { 
            top: 10px;
        }
    }
    
}

