@import '../../../utils.scss';

.solutionFunction_ {
    
    width: 100%;
    height: 1500px;
    text-align: center;
    padding: 20px 0px;
    box-sizing: border-box;
    color: white;
    background-color: #272727;

    .md_text {
        font-size: 80px;
        margin: -40px;
        text-align: center;
    }

    .bold_text_feature {
        font-size: 40px;
        font-weight: bolder;
        margin: 30px;
        .span_wrap {
            height: 60px;
            position: relative;
            box-sizing: border-box;
            &::before {
                content: "";
                border-top: 5px solid;
                width: 50px;
                position: absolute;
                top: 10;
                left: 0;
                box-sizing: border-box;
                padding: 100px 0px;
            }

        }
    }

    .inner_content {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    
    .imgad {
        position: relative;
        width: 70%;
        margin: 0 auto;
    }

    .inner_title {
        width: 100%;
        color: white;
        font-size: 25px;
        font-weight: bolder;
        position: relative;
        float: left;
        text-align: left;
        margin: 20px 0px;
    }

    .content_box{
        width: 33%;
        float: left;
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
            // position: absolute;
        }
    }

    .content_box_inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0
    }

    .inner_sub_title {
        width: 100%;
        color: white;
        font-size: 18px;
        float: left;
        text-align: left;
    }

    .imgbox {
        background-size: cover;
        float: left;
        position: relative;


        &::before{
            content: "";
            display: block;
            padding-top: 100%;
            position: absolute;
        }

        .imgintext{
            position: absolute;
        }

        &:hover{
            .inner_hover{
                opacity: 1;
            }
        }
    }

}