.second_ {
    
    .brand_name_wr {
        width: 100%;
        height: 60px;
        margin-bottom: 50px;

        .par {
            float: left;
            font-family: Montserrat-Medium;
            line-height: 24px;
            > * {
                font-family: Montserrat-Medium;
                font-size: 14px;
            }

            &.one {
                width: 20%;
            }

            &.two {
                width: 80%;
            }

            .wr_p {
                &.two {
                    font-family: Montserrat-Thin;
                    line-height: 20px;
                }
            }

        }

    }

    .text_wr {
        max-width: 100%;
        font-size: 35px;
        font-weight: 100;
        p {
            font-weight: 100;
            word-break: keep-all;
            // font-family: "Noto Sans KR", sans-serif;
            transition: all 0.2s;
        }
    }

}

@media (max-width: 1340px) {
    .second_ {
        .text_wr {
            font-size: 40px;
        }

       .brand_name_wr {
           width: 700px;
       }
    }
}

@media (max-width: 988px) {
    .second_ {
        .text_wr {
            font-size: 30px;
        }
    }
}

@media (max-width: 830px) {
    .second_ {
        .text_wr {
            font-size: 25px;
        }
    }
}

@media (max-width: 687px) {
    .second_ {
        .text_wr {
            font-size: 22px;
        }
    }
}


@media (max-width: 484px) {
    .second_ {
        .text_wr {
            font-size: 19px;
        }
    }
}

@media (max-width: 320px) {
    .second_ {
        .text_wr {
            font-size: 13px;
        }
    }
}

@media (max-width: 320px) {
    .second_ {
        .text_wr {
            font-size: 13px;
        }
    }
}