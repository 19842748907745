@import '../../../utils.scss';

.solution_ {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

.inner_text {
    width: 100%;
    height: auto;
}

    .md_text {
        font-size: 80px;
        text-align: center;
    }

    .portfolio{
        width: 0px;
        height: 50px;
        font-size: 30px;
        margin-top: 20px;
        margin-left: 3px;
        //background: $mainColor;
        color: $mainColor;
        background: $mainColor;
        margin-bottom: 100px;
        cursor: pointer;
        -webkit-transition: 0.5s !important;
        -o-transition: 0.5s !important;
        transition: 0.5s !important;
        
        &::before{
            position: absolute;
            width: 12px;
            height: 50px;
            content: '>';
            left: 270px;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
        }

        &:hover{
            width: 312px;
            color: #ffffff;
            padding-left: 20px;

            &::before {
                left: 290px;
            }

        }
        p{
            font-family: "Montserrat-Regular";
        }
    }
    .bold_text {
        font-family: "Montserrat-Bold";
    }

    .middle_text {
        font-size: 20px;
    }

    .weight600 {
        font-weight: 600;
    }

    .imgtop {
        width: 100%;
        height: 100px;
        text-align: center;
    }

    .time_text {
        font-size: 26px;
        font-weight: 100;
        line-height: 90px;

        span{
            font-size: 16px;
            font-family: "Montserrat-Medium";
            
        }

        .time_num {
            font-size: 150px;
            font-weight: 100;
            font-family: Montserrat-Thin;
        }

    }

}