@import '../../../utils.scss';

.teams {
    width: 100%;
    height: auto;
    .title_big {
        font-size: 90px;
        font-family: Montserrat-bold;
        text-align: center;
        margin-bottom: 50px;
        transition: all 0.2s;
    }

    .list_wr {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        height: 50vh;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        @include scroll-style;
        box-sizing: border-box;

        .wr {
            width: 100%;
            height: 100%;
            margin: 0 auto;
        }

        .list_all {
            // min-width: 270px;
            height: auto;
            float: left;
            transition: all 0.2s;

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            .list_name {
                font-size: 24px;
                font-family: Montserrat-bold;
                margin-bottom: 35px;
                padding-left: 1.5%;
            }
            
            .list_ {
                width: 100%;
                height: auto;

                .list_scroll {
                    width: auto;

                    li {
                        height: 35px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                            -ms-flex-align: center;
                                align-items: center;
                        white-space: nowrap;
                        width: 100%;
                        position: relative;

                        .hoverEmail {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 35px;
                            background-color: $mainColor;
                            width: 0;
                            // opacity: 0;
                            -webkit-transition: width 0.5s;
                            -o-transition: width 0.5s;
                            transition: width 0.5s;
                            z-index: 5;
                            opacity: 0;
                            padding-left: 10px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            transition: all 0.2s;

                            &.clip {
                                background-color: white;
                                color: $mainColor;
                                font-weight: bold;
                            }
                        }

                        p {
                            float: left;
                            font-size: 12px;
                            width: 50%;
                            position: relative;
                            z-index: 5;
                            display: flex;
                            align-items: center;
                            height: 35px;
                            margin: 0;
                        }   

                        span {
                            opacity: 0;
                            -webkit-transition: all 0.5s;
                            -o-transition: all 0.5s;
                            transition: all 0.5s;
                            position: absolute;
                            z-index: 5;
                            right: 4.5%;
                        }
                        
                        .name {
                            font-family: Montserrat-Medium;
                            -webkit-box-sizing: border-box;
                                    box-sizing: border-box;
                            padding-left: 3%;
                            display: flex;
                            align-items: center;
                            height: 35px;
                            margin: 0;
                            opacity: 1;
                            transition: all 0.1s;
                        }       
                        
                        &:hover {
                            .hoverEmail {
                                width: 110%;
                                opacity: 1;
                            }
                            span {
                                opacity: 1;
                            }
                            .name {
                                opacity: 0;
                            }
                            cursor: pointer;
                        }
                    }

                }

            }

        }

    }

}
@media (min-width: 1611px) {
    .list_wr {
        margin-top: 100px;
        width: 100%;

        .list_all {
            width: 22%;
            margin: 1.5%;
        }
    }
}

@media  (max-width: 1610px) {
    .list_wr {
        .list_all {
            width: 30%;
            margin: 1.6%;
        }
    }
}

@media (max-width: 1170px) {
    .list_wr {
        .list_all {
            width: 45% ;
            margin: 2.5%;
        }
    }
}

// @media (max-width: 780px) {
//     .list_wr {
//         width: 90%;
//         margin-left: auto;
//         margin-right: auto;
//         padding: 0 30px;
        
//         .list_all {
//             width: 45%;
//             margin: 2.5%;
//         }
//     }
// }

@media (max-width: 780px) {
    .title_big {
        font-size: 50px !important;
    }
    .list_wr {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
        box-sizing: border-box;
        
        .list_all {
            width: 100%;
            margin-bottom: 50px;
        }
    }
}

@media (max-width: 400px) {
    .title_big {
        font-size: 40px !important;
    }
    .list_wr {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
        box-sizing: border-box;
        
        .list_all {
            width: 100%;
            margin-bottom: 50px;
        }
    }
}