@import '../../../utils.scss';

.popup_wrapper_all {
    margin: auto;
    background-color: black;
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 10px;

    .header {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        position: relative;
        
        .X_btn {
            display: flex;
            align-items: center;
            width: 50px;
            height: 50px;
            top: -20px;
            right: -20px;
            text-align: center;
            position: absolute;
            text-align: right;
            transition: all 0.2s;
            background-color: transparent;
            box-shadow: none;
            color: white;

            &:hover {
                cursor: pointer;
                color: $mainColor;
                transform: translateY(5px)
            }

            &:active {
                transform: scale(1) translateY(5px);
                color: $subColor;
            }
            span {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 35px;
                font-weight: thin;
            }
        }
    }

    .popup_contetns {
        width: 100%;
        height: calc(100% - 50px);
        margin-top: 40px;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative; 
        @include scroll-style;
        box-sizing: border-box;

        .scroll_inner {
            position: absolute;
            width: 98%;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: auto;        
            box-sizing: border-box; 
            word-break: break-all  
            
        }

    }
}

@media (min-width: 701px) {
    .popup_wrapper_all {
        width: 80%;
        height: 90%;

        .X_btn {
            background-color: white;
            top: -50px;
            right: -50px;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(138,138,138,1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(138,138,138,1);
            box-shadow: 0px 0px 5px 0px rgba(138,138,138,1);
        }
    }
} 

@media (max-width: 1600px) {
    .popup_contetns {
        .description {
            font-size: 1rem;
            line-height: 2rem;
        }
    }

    .title_wrapper {
        font-size: 1rem;
    }

    .title_left, .title_right {
        font-size: 1rem;
    }

    .title {
        font-size: 1.5rem !important;
    }
}


@media (max-width: 520px) {
    .popup_contetns {
        .description {
            font-size: 0.8rem;
            line-height: 1.3rem !important; 
        }
    }

    .title_wrapper {
        font-size: 0.8rem;
    }

    .title_left, .title_right {
        font-size: 0.7rem;
    }

    .title {
        font-size: 1.3rem !important;
    }
}

@media (max-width: 700px) {
    .popup_wrapper_all {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0;
        padding: 40px 5px;
        .X_btn {
            right: -10px;
            top: -45px;
            span {
                font-size: 20px !important;
                color: white;
            }
        }
    }
}
