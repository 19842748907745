* {
    margin: 0;
    padding: 0;
}

.image_wrap {
    width: 100%;
    min-width: 200vh;
    min-height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    z-index: 3;

    img {
        position: relative;
        width: 100%;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
    }

}

.animation{
    animation-delay: -2s;
}

.black_back {
    display: block;
    position: relative;
    // top: -6.5px;
    left: 0;
    width: 100%;
    height: 100%;

    .before {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.6;
        z-index: 3;
    }

    &.black {
        .before {
            background-color: #000000;
            opacity: 0.6;
        }
    }

    &.white {
        .before {
            background-color: white;
            opacity: 1;
        }
    }

    .contents {
        top: 0;
        position: absolute;
        width: 70%;
        // background-color: red;
        color: white;
        text-align: left;
        height: 100%;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: flexbox;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0;
        z-index: 5;

        .inner_contetns {
            width: 100%;
            height: auto;
        }
    }

}