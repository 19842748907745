.section {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: opac;
            animation-name: opac;

    @-webkit-keyframes opac {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes opac {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    &.one {
        .md_text   {
            -webkit-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                    transform: translateY(-100%);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }

        .portfolio, .time_text {
            -webkit-transform: translateX(-1500px);
                -ms-transform: translateX(-1500px);
                    transform: translateX(-1500px);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }
        
        // .md_text {
        //     -webkit-transition-delay: 0.5s;
        //          -o-transition-delay: 0.5s;
        //             transition-delay: 0.5s;
        // }

        .md_text {
            -webkit-transition-delay: 0.4s;
            -o-transition-delay: 0.4s;
            transition-delay: 0.4s;
        }
        

        .portfolio  {
            -webkit-transition-delay: 0.8s;
            -o-transition-delay: 0.8s;
            transition-delay: 0.8s;
        }

        .time_text {
            -webkit-transition-delay: 0.6s;
            -o-transition-delay: 0.6s;
            transition-delay: 0.6s;
        }

        &.active {
            .md_text {
                -webkit-transform: translateY(0);
                    -ms-transform: translateY(0);
                        transform: translateY(0);
                opacity: 1;
            }

            .portfolio, .time_text {
                -webkit-transform: translateX(0);
                    -ms-transform: translateX(0);
                        transform: translateX(0);
                opacity: 1;
            }
       }
    }

    &.two {
        .text_wr {
            -webkit-transform: translateX(-150%);
                -ms-transform: translateX(-150%);
                    transform: translateX(-150%);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }
        .par  {
            &.one, &.two {
                -webkit-transform: translateY(-150%);
                    -ms-transform: translateY(-150%);
                        transform: translateY(-150%);
                opacity: 0;
                -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
                transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
                -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
                transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
                transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            }
        }
        
        &.active {
            .par {
                &.one, &.two {
                    -webkit-transform: translate(0, 0);
                        -ms-transform: translate(0, 0);
                            transform: translate(0, 0);
                    opacity: 1;
                }

                &.one {
                    -webkit-transition-delay: 0.4s;
                         -o-transition-delay: 0.4s;
                            transition-delay: 0.4s;
                }

                &.two {
                    -webkit-transition-delay: 0.6s;
                         -o-transition-delay: 0.6s;
                            transition-delay: 0.6s;
                }
            }

            .text_wr {
                -webkit-transition-delay: 0.8s;
                     -o-transition-delay: 0.8s;
                        transition-delay: 0.8s;
                -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                        transform: translate(0, 0);
                opacity: 1;
            }
        }
    }


    &.three {
        .sec {
            &:nth-child(even) {
                -webkit-transform: translateY(-100%);
                    -ms-transform: translateY(-100%);
                        transform: translateY(-100%);
            }

            &:nth-child(odd) {
                -webkit-transform: translatex(100%);
                    -ms-transform: translateY(100%);
                        transform: translateY(100%);
            }
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }


        &.active {
            .sec {
                -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                        transform: translate(0, 0);
                opacity: 1;
                &:nth-child(1) {
                    -webkit-transition-delay: 0.3s;
                    -o-transition-delay: 0.3s;
                    transition-delay: 0.3s;
                }

                &:nth-child(2) {
                    -webkit-transition-delay: 0.5s;
                    -o-transition-delay: 0.5s;
                    transition-delay: 0.5s;
                }

                &:nth-child(3) {
                    -webkit-transition-delay: 0.7s;
                    -o-transition-delay: 0.7s;
                    transition-delay: 0.7s;
                }

                &:nth-child(4) {
                    -webkit-transition-delay: 0.9s;
                    -o-transition-delay: 0.9s;
                    transition-delay: 0.9s;
                }
            }
        }
    }

    &.four {
        h1 , .slick-dots {
            -webkit-transform: translateY(-300%);
                -ms-transform: translateY(-300%);
                    transform: translateY(-300%);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }

        .slick-slider {
            -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }

        .SliderInner {
            -webkit-transform: translateY(-300%);
                -ms-transform: translateY(-300%);
                    transform: translateY(-300%);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }

        h1 {
            -webkit-transition-delay: 0.3s;
                 -o-transition-delay: 0.3s;
                    transition-delay: 0.3s;
        }

        &.active {
            h1, .slick-dots, .slick-slider, .SliderInner {
                -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                        transform: translate(0, 0);
                opacity: 1;
            }

            .slick-slider {
                -webkit-transition-delay: 1s;
                     -o-transition-delay: 1s;
                        transition-delay: 1s;
            }
        }
    }

    &.five {        
        .one h3, .two h3 {
                -webkit-transform: translateY(-1000px);
                    -ms-transform: translateY(-1000px);
                        transform: translateY(-1000px);
                opacity: 0;
                -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
                transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
                -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
                transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
                transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }

        .threeInner h3, .threeInner h4 {
            -webkit-transform: translateX(500px);
                -ms-transform: translateX(500px);
                    transform: translateX(500px);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }
        
        .emailArea {
            -webkit-transform: translateY(-100px);
                -ms-transform: translateY(-100px);
                    transform: translateY(-100px);
            opacity: 0;
            -webkit-transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            transition: opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
            -o-transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out;
            transition: transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99), opacity .8s ease-in-out, -webkit-transform .8s cubic-bezier(0.16, 0.68, 0.43, 0.99);
        }
        
        .threeInner:nth-child(1) {
            h3 {
                -webkit-transition-delay: 0.4s;
                     -o-transition-delay: 0.4s;
                        transition-delay: 0.4s;
            }
            h4 {
                -webkit-transition-delay: 0.7s;
                     -o-transition-delay: 0.7s;
                        transition-delay: 0.7s;
            }
            .emailArea {
                -webkit-transition-delay: 1.0s;
                     -o-transition-delay: 1.0s;
                        transition-delay: 1.0s;
            }

        }

        .threeInner:nth-child(2) {
            h3 {
                -webkit-transition-delay: 0.7s;
                     -o-transition-delay: 0.4s;
                        transition-delay: 0.4s;
            }
            h4 {
                -webkit-transition-delay: 0.7s;
                     -o-transition-delay: 0.7s;
                        transition-delay: 0.7s;
            }
            .emailArea {
                -webkit-transition-delay: 1.0s;
                     -o-transition-delay: 1.0s;
                        transition-delay: 1.0s;
            }
        }


        .threeInner:nth-child(3) {
            h3 {
                -webkit-transition-delay: 0.7s;
                     -o-transition-delay: 0.7s;
                        transition-delay: 0.7s;
            }
            h4 {
                -webkit-transition-delay: 1.0s;
                     -o-transition-delay: 1.0s;
                        transition-delay: 1.0s;
            }
            .emailArea {
                -webkit-transition-delay: 1.3s;
                     -o-transition-delay: 1.3s;
                        transition-delay: 1.3s;
            }
        }

            
        .one h3 {
            -webkit-transition-delay: 1.0s;
                 -o-transition-delay: 1.0s;
                    transition-delay: 1.0s;
        }

        .two h3 {
            -webkit-transition-delay: 1.3s;
                 -o-transition-delay: 1.3s;
                    transition-delay: 1.3s;
        }

        &.active {
            .one h3, .two h3, .threeInner h3, .threeInner h4, .emailArea {
                -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                        transform: translate(0, 0);
                opacity: 1;
            }

        }
    }



}