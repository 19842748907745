.mainImage {
    width: 100%;
    height: auto;

    img {
        width: 100%;
        height: auto;
    }
}

.overView {
    width: 90%;
    margin: 20px auto;
    color: white;
    display: flex;
    align-items: center;
    .title {
        white-space: nowrap;
        font-size: 2.5rem;
    }
    .border {
        width: 100%;
        margin-left: 20px;
        border: none;
        height: 100%;
        border-bottom: 2px solid white;
        margin-bottom: 0;
    }
}

.mainVideo {
    width: 100%;
    height: 50vh;

    video {
        width: 100%;
        height: auto;

    }
}

.description {
    width: 90%;
    margin: 40px auto;
    color: white;
    font-size: 1.5rem;
    line-height: 2rem;
}

.detailInner {
    width: 90%;
    margin: 20px auto;
    color: white;
    margin-top: 40px;

    .title_wrapper {
        width: 100%;
        display: flex;
        font-size: 1.5rem;
        .title_left {
            font-weight: bold;
            &::after {
                content: "|";
                margin: 0 10px;
            }
        }
    }

    .description {
        width: 100%;
        padding: 0;
        margin: 20px 0;
        line-height: 2rem;
    }

    .imageWrapper {
        width: 100%;
        margin: 30px auto;
        margin-bottom: 60px;

        // span {
        //     height: 100%;
        //     display: block;
        // }
        img {
            width: 100%;
            // height: 100%;
        }
    }
}

@media (min-width: 1441px) {
    .mainVideo {
        height: 40vh;
    }
}

@media (max-width: 1440px) {
    .mainVideo {
        height: 30vh;
    }
}

@media (max-width: 590px) {
    .mainVideo {
        height: 22vh;
    }
}

@media (max-width: 500px) {
    .mainVideo {
        height: 20vh;
    }
}

@media (max-width: 450px) {
    .mainVideo {
        height: 20vh;
    }
}

@media (max-width: 400px) {
    .mainVideo {
        height: 30vh;
    }
}