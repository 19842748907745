@import '../../../utils.scss';

* {
    transition: all 0.2s;
}

.imgAnimation {
    animation-delay: -2s;
}

.first_ {
    width: 100%;
    height: 100%;
    
    .md_text {
        margin-top : 30px;
        font-size: 90px;
        font-weight: 100;
        line-height: 92px;
        transition: all 0.2s;
    }

    .portfolio{
        transition: all 0.2s;
        width: 0px;
        height: 50px;
        margin-top: 20px;
        margin-left: 3px;
        color: $mainColor;
        background: $mainColor;
        margin-bottom: 50px;
        cursor: pointer;
        font-family: "Montserrat-Regular";


        .contents {
            font-size: 40px;
            display: inline;
            padding: 0 50px 0 0;
            box-sizing: border-box;
            position: relative;
            z-index: 6;
            color: $mainColor;
            transition: all 0.2s;

            span {
                line-height: 50px;
                font-size: 60px;
                transition: all 0.2s;
                position: absolute;
                right: -250px;
                top: 0;
            }
        }

        &:hover {
            color: white;
            .hoverContents {
                width: 600%;
                span {
                    opacity: 1;
                }
            }

            .contents {
                padding-left: 10px;
                color: white;
            }
        }

        .hoverContents {
            position: absolute;
            z-index: -10;
            top: 0;
            left: 0;
            height: 100%;
            width: 0%;
            background-color: $mainColor;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            transition: width 0.5s;
        }

    }
    .bold_text {
        font-family: "Montserrat-Bold";
    }

    .time_text {
        font-size: 26px;
        font-weight: 100;
        line-height: 90px;

        span{
            font-size: 16px;
            font-family: "Montserrat-Medium";
            
        }

        .time_num {
            font-size: 150px;
            font-weight: 100;
            font-family: Montserrat-Thin;
        }

    }
}

@media (max-width: 1024px) {

    .first_ {

        .portfolio { 
            margin-bottom: 50px;
            .contents {
                font-size: 30px;
                span {
                    line-height: 40px;
                    font-size: 60px;
                    right: -90px;
                }
            }
        }

        .md_text {
            margin-top : 50px;
            font-size: 70px;
            line-height: 70px;
        }

        .time_text{
            line-height: 50px;

            span {
                width: 300px; 
                font-size: 30px;
                display: flex;
              }
              .time_num {
                  display: none;
              }
          }
    }

   
   
}

@media (max-width: 768px) {
    .first_ {

        
        .portfolio { 
            .contents {
                font-size: 40px;
            }
            span {
                line-height: 50px !important;
                font-size: 60px;
                right: -90px;
            }
        }

        .md_text {
            font-size: 50px;
            line-height: 50px;
        }

        .time_text{
            line-height: 60px;

            span {
                  font-size: 30px;
                  display: flex;
              }
              .time_num {
                  display: none;
              }
          }
    }
}

@media (max-width: 568px) {
    .first_ {

        .portfolio { 
            .contents {
                font-size: 20px;
                padding: 10px 0;
                padding-right: 50px;
                box-sizing: border-box;
                span {
                    line-height: 45px !important;
                    font-size: 60px;
                    right: -60px;
                }
            }
        }

        .md_text {
            font-size: 50px;
            line-height: 50px;
        }

        .time_text{
            font-size: 20px;
            line-height: 50px;

            span {
                 width: 300px ; 
                font-size: 30px;
                display: flex;
              }
              .time_num {
                  display: none;
              }
          }
    }
}

@media (max-width: 320px) {
    .first_ {


        .portfolio {
            margin-left: -10px; 
            .contents {
                font-size: 20px;
            }
        }

        .md_text {
            font-size: 30px;
            line-height: 30px;
            margin-left: -10px;
        }

        .time_text{
            font-size: 15px;
            line-height: 30px;

            span {
                 width: 300px ; 
                font-size: 30px;
                display: flex;
              }
              .time_num {
                  display: none;
              }
          }
    }
}