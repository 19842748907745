.contact {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    color: black;
    top: 200px;
    animation-delay: 0.5s;
    
    .scroll {
        width: 100%;
        height: auto;
    }

    .contact_us {
        font-family: 'Montserrat';
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: bold;
        color: black;
        position: relative;

        .contents{
            position: relative;
            width: 40%;
            height: 40%;
            text-align: left;
        }
    }

    .span_wrap {
        height: 40px;
        margin-top: 30px;
        position: relative;
        box-sizing: border-box;
        &::before {
            content: "";
            
            width: 700px;
            left: 0;
            box-sizing: border-box;
            float: left;
            color: black;
            top: 10px;
            position: absolute;
            padding-left: 20px;
        }

    }

    .titleLine { 
        margin-left: 5px;
        width: 500px;
        border-top: 3px solid;
        margin-bottom: 30px;
    }
    .firstText {
        font-weight: bolder;
    }

    .textDesc {
        display: flex;
    }

    .explanation {
        font-weight: bolder;
        padding: 20px;
        font-size: 10px;
        margin-top : 300px;
        float: left;
        color: black;
    }

    .imgMap {
        padding: 60px 0px;

        iframe { 
            width: 909px;
            border: 0;
            height: 351px;
            
        }
    }
}

@media(min-height: 1366px) { 

    .contact { 
        
        .imgMap { 
            iframe { 
                width: 900px;

            }
        }

    }
}

@media(max-width: 1660px) { 


    .contact { 
        
      
        .contact_us {
            margin-left: 20px;
        }
        .imgMap { 
            padding: 30px 25px;

            iframe { 
                width: 100%;

            }
        }

        .explanation {
            position: absolute;
            font-weight: bolder;
            padding: 20px;
            font-size: 10px;
            margin-top : 400px;
            color: black;
        }

        .titleLine { 
            width: 700px;
        }
    
    }
    
    .imgMap {
        width: 100%;
    }

    .textDesc {
        position: absolute;
    }

}

    @media(max-width: 1311px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }

        .titleLine{
            width: 450px;
        }
    }
}


@media(max-width: 1111px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }
    }
}

@media(max-width: 930px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }
    }
}



@media(max-width: 823px) { 

    .contact { 
        height: 80%;
        top: 50px;
        .explanation {
            margin-top : 400px;
        }
    }
}


@media(max-width: 812px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }
    }
}

@media(max-width: 768px) { 

    .contact { 
        height: 80%;
        .explanation {
            margin-top : 400px;
        }
    
    }
}


@media(max-width: 484px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }
    }
}


@media(max-width: 414px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }

        .titleLine { 
            width: 250px;
        }
    }
}


@media(max-width: 330px) { 

    .contact { 
        .explanation {
            margin-top : 400px;
        }

        .titleLine { 
            width: 230px;
        }

    }
}
