body {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", 
     monospace;
}

@font-face {
  font-family: "Montserrat-Black";
  font-style: normal;
  font-weight: 900;
  src: url("./_assets/fonts/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./_assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: 200;
  src: url("./_assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 400;
  src: url("./_assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 300;
  src: url("./_assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Thin";
  font-style: normal;
  font-weight: 100;
  src: url("./_assets/fonts/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  font-style: normal;
  font-weight: 100;
  src: url("./_assets/fonts/Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: bold;
  font-weight: 600;
  src: url("./_assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url("./_assets/fonts/NotoSansCJKkr-Thin.otf");
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url("./_assets/fonts/NotoSansCJKkr-Light.otf");
}
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 400;
   src: url("./_assets/fonts/NotoSansCJKkr-Regular.otf");
 }
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 500;
   src: url("./_assets/fonts/NotoSansCJKkr-Medium.otf");
 }
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 700;
   src: url("./_assets/fonts/NotoSansCJKkr-Bold.otf");
 }
@font-face {
   font-family: 'Noto Sans KR';
   font-style: normal;
   font-weight: 900;
   src: url("./_assets/fonts/NotoSansCJKkr-Black.otf");
 } 

*, body {
  font-family: "Montserrat-ExtraLight", "Noto Sans KR";
  font-weight: 100;
}
