@import '../../../utils.scss';

.third_{
    .titleArea{
        transition: all 0.2s;
        width: 370px;
        height: 100px;
        float: left;
        margin-bottom: 55px;
        img{
            height: 40px;
        }
        h1{
            font-size: 80px;
            font-family: "Montserrat-Bold";
            margin-top: 40px;
        }
    }
    
    .founderUnderline{
        transition: all 0.2s;
        clear: both;
        width: 694px;
        height: 4px;
        background: #ffffff;
        margin-bottom: 45px;
    }

    .infoArea{
        font-size: 16px;
        h2{
            font-family: "Montserrat-Medium";
            margin-bottom: 8px;
        }
        p{
            margin-bottom: 10px;
            font-family: "Montserrat-Thin";
            font-weight: 100;
        }

        .info{
            margin-bottom: 45px;
        }
    }
}

@media (min-width: 2281px) {
    .indexing {
        font-size: 30px;
    }

    .show_title {
        font-size: 62px;
    }

    .hide_desc {
        font-size: 17px;
        line-height: 30px;
    }
}


@media (min-width: 661px) {
    .indexing {
        font-size: 30px;
    }

    .show_title {
        font-size: 62px;
    }

    .hide_desc {
        font-size: 17px;
        line-height: 30px;
    }
}

@media (min-width: 841px ) {
    .over_section_third {
        width: 100%;
        height: 100%;
        // background-color: red;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
    
        &:hover {
            .sec {
                transition: all 0.2s !important;
            }
        }
    
        .sec {
            width: 25%;
            // float: left;
            box-sizing: border-box;
            padding: 0 20px;
            height: 100%;
            border-right: 1px solid #ffffff52;
    
            .inner_wrapper {
                margin-top: 30vh;
            }   
    
            &:nth-last-child(1) {
                border: none;
            }
    
            .indexing {
                width: 100%;
                margin-bottom: 5vh;
            }
    
            .show_title {
                width: 100%;
                margin-bottom: 5vh;
                word-break: keep-all;
            }
    
            .hide_desc {
                width: 100%;
                opacity: 0;
                box-sizing: border-box;
    
            }
    
            &:hover {
                background-color: #ff661470;
                width: 40% !important;
                transition: all 0.2s !important;
    
                .hide_desc {
                    opacity: 1;
                }
            }
        }
    }
}

@media (max-width: 2280px) {
    .indexing {
        font-size: 25px;
    }

    .show_title {
        font-size: 50px;
    }

    .hide_desc {
        font-size: 17px;
        line-height: 30px;
    }
}

@media (max-width: 1970px) {
    .indexing {
        font-size: 20px;
    }

    .show_title {
        font-size: 40px;
    }

    .hide_desc {
        font-size: 15px;
        line-height: 25px;
    }
}

@media (max-width: 1520px) {
    .indexing {
        font-size: 20px;
    }

    .show_title {
        font-size: 30px;
    }

    .hide_desc {
        font-size: 15px;
        line-height: 25px;
    }
}

@media (max-width: 1200px) {
    .indexing {
        font-size: 20px;
    }

    .show_title {
        font-size: 20px;
    }

    .hide_desc {
        font-size: 13px;
        line-height: 23px;
    }
}

@media (max-width: 1080px) {
   .third_ {
       .titleArea {
           h1 {
               font-size: 70px;
           }
       }
       .founderUnderline {
           width: 500px;
       }
   }
}

@media (max-width: 840px) {
    .over_section_third {
        width: 100%;
        height: 100%;
        // background-color: red;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 10vh;
        &:hover {
            .sec {
                transition: all 0.2s !important;
            }
            margin-top: 4vh;
        }
    
        .sec {
            height: 20%;
            width: 100%;
            box-sizing: border-box;
            padding: 20px 20px;
            border-bottom: 1px solid #ffffff52;
            // margin-bottom: 5%;
  
    
            &:nth-last-child(1) {
                border: none;
            }
    
            .indexing {
                width: 100%;
                margin-bottom: 5vh;
                font-weight: bold;
            }
    
            .show_title {
                width: 100%;
                margin-bottom: 5vh;
                word-break: keep-all;
            }
    
            .hide_desc {
                width: 100%;
                opacity: 0;
                box-sizing: border-box;
    
            }
    
            &:hover {
                background-color: #ff661470;
                height: 30%;
                transition: all 0.2s !important;
    
                .hide_desc {
                    opacity: 1;
                }
            }
        }
    }
} 

@media (max-width: 330px) {
    .indexing {
        margin-bottom: 2vh !important;
        font-size: 17px;
        font-weight: bold;
    }

    .show_title {
        margin-bottom: 2vh !important;
        font-size: 18px;
    }
} 


@media (max-width: 840px) and (max-height:360px) {
    .indexing {
        margin-bottom: 2vh !important;
        font-size: 17px;
        float: left;
        width: 10% !important;
        font-weight: bold;
    }

    .show_title {
        margin-bottom: 2vh !important;
        font-size: 18px;
        width: 90% !important;
        float: left;
    }

    .hide_desc {
        font-size: 12px !important; 
    }
}

@media (max-width: 768px) {
    .third_ {
        .titleArea {
            h1 {
                font-size: 60px;
            }
        }
        .founderUnderline {
            width: 400px;
        }
    }

}

@media (max-width: 606px) {
    .third_ {
        .titleArea {
            h1 {
                font-size: 60px;
            }
        }
        .founderUnderline {
            width: 300px;
        }
    }
}

@media (max-width: 476px) {
    .third_ {
        .titleArea {
            h1 {
                font-size: 40px;
            }
        }
        .founderUnderline {
            width: 200px;
        }
    }
}

@media (max-width: 840px) and (max-height:760px) {
    
    .sec {
        height: 15% !important;
    
        &:hover {
            background-color: #ff661470;
            height: 40% !important;
            transition: all 0.2s !important;

            .hide_desc {
                opacity: 1;
            }
        }
    }

    .indexing {
        margin-bottom: 2vh !important;
        font-size: 17px;
    }

    .show_title {
        margin-bottom: 2vh !important;
        font-size: 18px;
    }

    .hide_desc {
        font-size: 12px !important; 
    }
}